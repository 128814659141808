<template>
  <section class="section why watch" :class="[{ animated: getVisibleStage.includes('why-section') }, getCurrentLangs.lang]" id="why-section">
    <div class="why__top">
      <Lottie
        :options="defaultOptions"
        class="why__top--media"
        @animCreated="handleAnimation"
        id="why-animation"
        @play="startPlay"
      />
      <div class="why__top--content">
        <h2 class="section__title why__title iosTitle type1">
          <span class="text-wrapper">
            <span class="letters">
              {{ `${$t('mainPage.why.title[0]') + '&nbsp;'}` }}
            </span>
          </span>
          <span class="text-wrapper">
            <span class="letters">
              {{ $t('mainPage.why.title[1]') }}
            </span>
          </span>
        </h2>
        <h4 class="section__subtitle why__subtitle grow" v-html="$t('mainPage.why.subtitle')"></h4>
        <p class="section__description why__description grow ">{{ $t('mainPage.why.description') }}</p>
      </div>
    </div>
    <ul class="why__list grow">
      <li class="why__item section__item">
        <h5 class="why__item--title section__item--title">{{ $t('mainPage.why.list.firstItem.title') }}</h5>
        <p class="why__item--text section__item--text">{{ $t('mainPage.why.list.firstItem.subtitle') }}</p>
        <p class="why__item--description section__item--description">{{ $t('mainPage.why.list.firstItem.description') }}</p>
        <div class="why__item--link section__item--link cursor-hover">
          <router-link to="/contact">
            <span class="underline">
              {{ $t('mainPage.why.list.firstItem.link') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
        </div>
      </li>
      <li class="why__item section__item">
        <h5 class="why__item--title section__item--title">{{ $t('mainPage.why.list.secondItem.title') }}</h5>
        <p class="why__item--text section__item--text">{{ $t('mainPage.why.list.secondItem.subtitle') }}</p>
        <p class="why__item--description section__item--description">{{ $t('mainPage.why.list.secondItem.description') }}</p>
        <div class="why__item--link section__item--link second cursor-hover">
          <router-link to="/contact">
            <span class="underline">
              {{ $t('mainPage.why.list.secondItem.link') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
        </div>
      </li>
      <li class="why__item section__item">
        <h5 class="why__item--title section__item--title">{{ $t('mainPage.why.list.thirdItem.title') }}</h5>
        <p class="why__item--text section__item--text">{{ $t('mainPage.why.list.thirdItem.subtitle') }}</p>
        <p class="why__item--description section__item--description">{{ $t('mainPage.why.list.thirdItem.description') }}</p>
        <div class="why__item--link section__item--link cursor-hover">
          <router-link to="/contact">
            <span class="underline">
              {{ $t('mainPage.why.list.thirdItem.link') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import Lottie from 'vue-lottie';
import {mapGetters} from 'vuex';
import animationData from '@/assets/style/animation/why.json';
import getElementVisible from '@/helper';
import anime from 'animejs';

export default {
  name: 'WhySection',
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
      // animationSpeed: 1,
    };
  },
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs',
    ]),
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    play() {
      this.anim.play();
    },
    stop() {
      this.anim.stop();
    },
    startPlay() {
      setTimeout(() => {
        if (getElementVisible('why-animation')) {
          this.play();
          // console.log()
        }
      }, 0);
    },
  },
  mounted() {
    // this.startPlay();
    // body.addEventListener('scroll', this.startPlay);
    document.addEventListener('scroll', () => {

      this.startPlay()

      if (!this.getVisibleStage.includes('why-section')) {
        // this.startPlay();
        const whySection = document.querySelector('#why-section');
        const textWrapperAll = whySection.querySelectorAll('.text-wrapper .letters');
        textWrapperAll.forEach((e, index) => {
          const textWrapper = e;

          if (!Number.isInteger(index / 2)) {
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");
          } else {
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
          }

          anime.timeline({
            loop: false,
          }).add({
            targets: '.text-wrapper .letter',
            translateY: ['1.2em', 0],
            translateZ: 0,
            duration: 1000,
            delay: (el, i) => 60 * i,
          });
        });
      }

    });
  },
};
</script>

<style lang="scss" scoped>
.why {
  padding-top: 176px;
  position: relative;
  text-align: left;
  transition-duration: .3s;

  .why__top--media {
    height: unset !important;
  }

  &__title {
    margin-bottom: 26px;

    &.iosTitle.type1 {
      .text-wrapper:nth-of-type(2) {
        @media screen and (max-width: 425px) {
          padding-bottom: 4px;
        }
      }
    }
  }

  &__subtitle {
    margin-bottom: 50px;
  }

  &__top {
    // display: flex;
    // align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
    // grid-column-gap: 5%;
    margin-bottom: 90px;
    padding-right: 144px;
    padding-left: 350px;
    padding-right: 500px;
    // &--content {
    //   margin-left: 90px;
    // }
    &--media {
      max-width: 360px;
      object-fit: contain;
    }

    &--content {
      padding-left: 70px;
    }
  }

  &__list {
    padding-left: 405px;
    padding-right: 439px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5%;
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--link {
      min-height: 46px;
    }

    &--text {
      font-size: 16px;
    }

    &:nth-of-type(2) {
      .why__item--link {
        width: 100%;
        display: inline-block;
      }
    }
  }

  &__description {
    width: 75%;
  }
}

@media screen and (max-width: 1600px) {
  .why {
    &__top {
      margin-bottom: 0px;
      padding-left: 180px;
      padding-right: 362px;

      &--media {
        max-width: 360px;
      }
    }

    &__list {
      margin-top: 100px;
      padding-left: 180px;
      padding-right: 257px;
      grid-column-gap: 109px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .why {
    &__top {
      padding-left: 140px;
      padding-right: 260px;

      &--content {
        padding-left: 60px;
      }
    }

    &__list {
      padding-left: 165px;
      padding-right: 199px;
      grid-column-gap: 66px;
    }
  }
}

@media screen and (max-width: 1370px) {
  .why {
    &__top {
      padding-left: 140px;
      padding-right: 210px;

      &--content {
        padding-left: 36px;
      }
    }

    &__list {
      padding-left: 165px;
      padding-right: 199px;
      grid-column-gap: 40px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .why {
    &__top {
      padding-left: 100px;
      padding-right: 120px;

      &--content {
        padding-left: 38px;
      }
    }

    &__list {
      padding-left: 120px;
      padding-right: 120px;
      grid-column-gap: 66px;
    }
  }
}

@media screen and (max-width: 960px) {
  .why {
    &__top {
      padding-left: 90px;
      padding-right: 51px;
    }

    &__list {
      padding-left: 44px;
      padding-right: 52px;
      grid-column-gap: 32px;
    }

    &__title {
      margin-bottom: 18px;
    }

    &__description {
      width: 80%;
    }

    &__subtitle {
      margin-bottom: 36px;
    }
  }
}

@media screen and (max-width: 768px) {
  .why {

    &__title {
      margin-bottom: 6px;
      line-height: 1.2em;
    }

    &__subtitle {
      margin-bottom: 8px;
    }

    &__top {
      margin-bottom: 56px;
      padding-left: 90px;

      &--content {
        margin-left: 0;
        padding-left: 22%;
      }

      &--media {
        max-width: 220px;
      }
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 60px;
    }
  }
}

@media screen and (max-width: 650px) {
  .why {
    &__top {
      &--content {
        margin-left: 10%;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .why {
    padding-top: 87px;
    padding-left: 0;
    padding-right: 0;

    &__top {
      grid-template-columns: 1fr;
      flex-direction: column;
      margin-bottom: 56px;
      padding-left: 20px;
      padding-right: 20px;

      &--content {
        margin-top: 35px;
        margin-left: 0;
        padding-left: 0;
        width: 100%;
      }

      &--media {
        margin-left: auto !important;
        margin-right: auto;
        max-height: 240px;
      }
    }

    &__list {
      grid-template-columns: 1fr;
      margin-top: 0;
      padding: 0 20px;
      grid-row-gap: 28px;
    }
  }
}
</style>
